/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/jquery-colorbox@1.6.4/jquery.colorbox.min.js
 * - /npm/jquery.easing@1.4.1/jquery.easing.min.js
 * - /npm/lazyload@2.0.0-rc.2/lazyload.min.js
 * - /npm/swiper@11.2.1/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
